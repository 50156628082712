import { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import './Contact.scss'

const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault()

        emailjs.sendForm('service_hwv7w78', 'template_i71frv9', form.current, 'gUBDSuq-T52RXzCpa')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            })
        
            setName('')
            setEmail('')
            setSubject('')
            setMessage('')
    }
    return ( 
        <>
            <form className='form' ref={form} onSubmit={sendEmail} >
                <h2>Contact Me</h2>
                <br />
                
                <ul>
                    <li><input className='iName' type="text" value={name} onChange={e=> setName(e.target.value)} name='name' placeholder='Name' required /></li>
                    <li><input className='iEmail' type="email" value={email} onChange={e=> setEmail(e.target.value)} name='email' placeholder='Email' required /></li>
                    <li><input className='iSubject' type="text" value={subject} onChange={e=> setSubject(e.target.value)} name='subject' placeholder='Subject' required /></li>
                    <li><textarea className='iMessage' name="message" value={message} onChange={e=> setMessage(e.target.value)} rows="7" placeholder='Message' required></textarea></li>
                    <li><button className='iSubmit' type='submit' >Send Message</button></li>
                </ul>
            </form>
        </>
     )
}
 
export default Contact