import './About.scss'

const About = () => {
  return (
    <div className="about">
      <h2>About Me</h2>
      <br />

      <article>
        I am proactive, a highly motivated and enthusiastic FrontEnd Developer.
        My goal is to deliver solutions for different problems that may appear
        down the road following better practices. I actively seek for new
        technologies and stay updated. I am keen to learn, to adapt and
        maintaining a flexible approach in a Challenging work environment which
        changes at a fast pace.
      </article>
    </div>
  )
}

export default About
