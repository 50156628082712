import './Home.scss'
import InternalCta from '../hoc/InternalCta'
import ExternalCta from '../hoc/ExternalCta'
import CV from '../../assets/CV/GMataCV.pdf'


const Home = () => {
    return ( 
        <div className='home'>
            <InternalCta href='/about'><h1>Guildyn Mata</h1></InternalCta>
            <br />
            <InternalCta href='/works'><h3>Frontend Developer</h3></InternalCta>
            <br />
            <ExternalCta active='active' href={CV} target='_self' download>Download CV</ExternalCta>
        </div>
     );
}
 
export default Home;