import './Works.scss'
import GH from '../../assets/IMG/GH.png'
import YM from '../../assets/IMG/YM.png'
import TV from '../../assets/IMG/TV.png'
import ExternalCta from '../hoc/ExternalCta'

const data = [
  {
    id: 1,
    Image: GH,
    title: 'Github API',
    github: 'https://github.com/Guildyn/react-dev',
    demo: '',
  },
  {
    id: 2,
    Image: TV,
    title: 'Team Member',
    github: 'https://github.com/Guildyn/team-members',
    demo: '',
  },
  {
    id: 3,
    Image: YM,
    title: 'Your Money',
    github: 'https//github.com',
    demo: 'https://yourmoney-2bb1d.web.app/login',
  },
]

const Works = () => {
  return (
    <div className="works">
      <h2>Examples</h2>
      <br />

      <div className='card'>
        {data.map((work) => (
          <article className="swiper" key={work.id}>
            <div className="preview">
              <img src={work.Image} alt={work.title} />
            </div>
            <h3>{work.title}</h3>
            <div className="cta">
              <ExternalCta href={work.github}>Github</ExternalCta>
              {work.demo && <ExternalCta href={work.demo}>Live Demo</ExternalCta>}
            </div>
          </article>
        ))}
      </div>
    </div>
  )
}

export default Works
