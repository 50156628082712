import { ImLinkedin, ImGithub, ImTwitter, ImSkype } from 'react-icons/im'
import ExternalCta from '../hoc/ExternalCta'
import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <span className='footerLogo'>GM</span>
      <ul className='footerSocials'>
        <li>
          <ExternalCta href="https://www.linkedin.com/in/guildyn-mata-51b27796/">
            <ImLinkedin />
          </ExternalCta>
        </li>
        <li>
          <ExternalCta href="https://github.com/Guildyn">
            <ImGithub />
          </ExternalCta>
        </li>
        <li>
          <ExternalCta href="https://twitter.com/MagnusGMata">
            <ImTwitter />
          </ExternalCta>
        </li>
        <li>
          <ExternalCta href="skype:live:guillyan1">
            <ImSkype />
          </ExternalCta>
        </li>
      </ul>

      <div className='footerCopyright'>
        <small>&copy; Guildyn. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer
