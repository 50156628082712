import './Navbar.scss'
import { FaUser, FaEnvelope } from 'react-icons/fa'
import { MdWork } from 'react-icons/md'
import { AiFillHome } from 'react-icons/ai'


import NavButton from './NavButton'

const Navbar = () => {
  return (
    <nav>
      <NavButton
        classN='navHome'
        href="/"
      >
        <AiFillHome />
      </NavButton>
      <NavButton
        classN='navAbout'
        href="/about"
      >
        <FaUser />
      </NavButton>
      <NavButton
        classN='navWorks'
        href="/works"
      >
        <MdWork />
      </NavButton>
      <NavButton
        classN='navContact'
        href="/contact"
      >
        <FaEnvelope />
      </NavButton>
    </nav>
  )
}

export default Navbar