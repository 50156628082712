import React from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.scss'

const NavButton = ({ classN, href, children }) => {
  const activeStyle = {
    color: '#ff5e00',
  }
  return (
    <NavLink
      to={href}
      className={`icon ${classN}`}
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      <div className='iconValue'>{children}</div>
    </NavLink>
  )
}

export default NavButton
