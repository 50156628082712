import './Layout.scss'
import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const Layout = () => {
    return (
        <div >
            <Navbar />
            <div className='main'>
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}

export default Layout